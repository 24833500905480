@import "../../../../scss/theme-bootstrap";

.gnav-account-formatter {
  .gnav-account {
    &__content-container {
      &__toggle {
        &-register {
          display: none;
        }
      }
    }
  }
}
